<template>
    <button :class="['button', cls]"><slot/>
      <span v-if="withMargin" style="margin-right: 0.5rem"></span>
      <div v-if="icon"
           class="btnIcon"
           :style="{'background-image': 'url(' + icon + ')'}">
      </div>
    </button>
</template>

<script>
export default {
  name: "my-button",
  props: {
    withMargin: {
      type: Boolean,
      default: false
    },
    cls: {
      type: String,
      default: 'grey',
      validator: value => {
        return [
          'grey',
          'doAction',
          'withBorder',
          'setPeriod',
          'clearFilter',
          'filter',
          'arrow',
          'red',
        ].includes(value)
      }
    },
    icon:{
      type: String,
    },
  },
  computed: {
    btnClass(){
      if (this.cls === 'doAction'){
        return 'doAction'
      } else if (this.cls === 'red') {
        return 'red'
      } else if (this.cls === 'withBorder') {
        return 'withBorder'
      } else if (this.cls === 'clearFilter') {
        return 'clearFilter'
        } else if (this.cls === 'filter') {
        return 'filter'
      } else if (this.cls === 'setPeriod') {
        return 'setPeriod'
      } else if (this.cls === 'arrow') {
        return 'arrow'
      } else {
        return 'grey'
      }
    },
  }
}
</script>

<style scoped>
.button{
  border-radius: 4px;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #FFFFFF;
  padding: 0.75rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.grey {
  background: #808B8F;
  border: 1px solid transparent;
  width: 100%;
  max-width: 170px;
}
.grey:hover {
  background: transparent;
  border: 1px solid #808B8F;
  color: #808B8F;
}
.btnIcon {
   -webkit-transition: -webkit-transform .8s ease-in-out;
          transition:         transform .8s ease-in-out;
}
.filter:hover .btnIcon{
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}
.arrow {
  background: #FFFFFF;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  border: none;
  padding: 10px;
}
.red {
  background: #BC0000;
  border: 1px solid transparent;
  width: 100%;
  min-width: 138px;
}
.red:hover {
  border: 1px solid #BC0000;
  color: #BC0000;
  background: transparent;
}
.doAction {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #BC0000;
  padding: 0;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  margin-left: auto;
  /*margin-bottom: 24px;*/
}
.doAction:hover .btnIcon {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg)
}
.withBorder {
  /*padding: 11px 14px;*/
  border: 1px solid #BC0000;
  border-radius: 4px;
}
.withBorder:hover {
  border: 1px solid #BC0000;
  color: #fff;
  background: #BC0000;
}
.filter {
  padding: 11px 14px;
  border: 1px solid #BC0000;
  border-radius: 4px;
}
.filter:hover {
  padding: 11px 14px;
  border: 1px solid #BC0000;
  border-radius: 4px;
  color: #BC0000;
}
.filter:active {
  border: 1px solid #BC0000;
}
.setPeriod {
  background: #F9F9FC;
  border-radius: 4px;
  font-size: 14px;
  line-height: 21px;
  color: #2B4049;
  border: none;
  padding: 10px 0;
  width: 100px;
  margin-bottom: 32px;
  margin-right: 4px;
}
.clearFilter {
  border: 1.4px solid #C4C4C4;
  background: transparent;
  border-radius: 4px;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #808B8F;
  max-width: 200px;
  padding: 11px;
}

.btnIcon{
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 1.25rem;
  width: 1.25rem;
}

/* lg-устройства (больше или равно 992px) */
@media (min-width: 992px) {
  /* CSS для: 992px <= ширины <= 1119px */
  .doAction {
    margin-bottom: 0;
  }
  .setPeriod {
  margin-right: 16px;
}
}

</style>