import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VCalendar from 'v-calendar'
import VueAxios from "vue-axios"
import axios ,{Axios} from "axios";
import 'v-calendar/dist/style.css'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min"
import "./assets/css/style.css"
import "./assets/css/font.css"
import {global_objects} from "./global_objects";

const app = createApp(App,{
    http: Axios,
})


app.config.globalProperties.glb = global_objects

app.use(store)
app.use(VCalendar, {})
app.use(router)
app.use(VueAxios, axios)
app.mount('#app')
