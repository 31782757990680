<template>
  <div class="d-flex flex-xl-column align-items-center avatarContainer mx-auto">
    <div class="changePictureWrap">
      <label @change="change()" for="avatar" :style="{ backgroundImage: 'url('+ getPicture() +')'}" class="labelPicture" ref="imageDisplay"></label>
      <input @change="change()" id="avatar" ref="fileinput" type="file" accept="image/*" class="d-none" autocomplete="off">
    </div>
  </div>
</template>

<script>
import MyButton from "@/components/UI/MyButton";
export default {
  name: "ChangePicture",
  components: {MyButton},
  props: {
    picture: {
      type: String
    }
  },
  data: ()=>{
    return{
    }
  },

  methods:{
    getPicture(){
      return this.picture? this.glb.getMediaUrl(this.picture): require('../../../assets/media/defaultAvatar.svg');
    },
    change(){
      let vm = this
      let file = vm.$refs.fileinput.files[0]
      let fileName = vm.$refs.fileinput.value
      let ext = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase()
      if (file &&  (ext === "png" || ext === "jpeg" || ext === "jpg")){
        let reader = new FileReader()
        reader.onload = function (e) {
          console.log(vm.$refs.imageDisplay)
           vm.$refs.imageDisplay.style.backgroundImage=`url('${e.target.result}')`
        }
        reader.readAsDataURL(file);

        vm.$emit('update:modelValue', file)
      }
    }
  }
}
</script>

<style scoped>

.changePictureWrap {
  height: 100%;
  width: 100%;
}
.labelPicture{
  cursor: pointer;
  background-repeat: no-repeat;
  border-radius: 1.25rem;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
}

</style>
