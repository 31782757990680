<template>
  <my-modal forbid-close
            cls="fullScreen"
            ref="modal">
    Остался последний шаг регистрации. <br> Вам на почту отправлена ссылка.<br> Пройдите по ней для активации аккаунта.
  </my-modal>
</template>

<script>
import MyModal from "@/components/MyModal";
export default {
  name: "VerifyEmailModal",
  components: {MyModal},
  data: ()=>{
    return {
      interval: null,
    }
  },
  mounted() {


  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods:{
    open(){
        this.interval = setInterval(()=>{
        this.$store.dispatch('GET_USER_DETAILS')
        console.log('this.$store.state.user.isEmailVerified >', this.$store.state.user.isEmailVerified)
          if (this.$store.state.user.isEmailVerified) {
          this.close()
          }
        }, 5000)
        this.$refs.modal.open()
    },
    close(){
      console.log(this.$refs.modal)
      this.$refs.modal.isVisible = false
      clearInterval(this.interval)
    }
  }
}
</script>

<style scoped>
.windowWrap .pt-2 {
    padding-top: 15%!important;
    text-align: center!important;
}
</style>