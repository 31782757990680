import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import Base from "../views/Base"
import MyCards from '../views/personalCabinet/myCards/MyCards'
import HistoryOperation from '../views/personalCabinet/historyOperations/HistoryOperation'
import Profile from "@/views/personalCabinet/profile/Profile";
import Quality from "@/views/quality/Quality";
import ForMotorists from "@/views/forMotorists/ForMotorists";
import News from "@/views/news/News";
import AboutCompany from "@/views/aboutCompany/AboutCompany";
import PersonalCabinet from "@/views/personalCabinet/PersonalCabinet";
import Promotions from "@/views/personalCabinet/Promotions";
import Registration from "@/views/personalCabinet/Registration";
import AboutProgram from "@/views/personalCabinet/AboutProgram";
import Login from "@/views/personalCabinet/Login";

const routes = [
    // {path: '/registration', name: 'Registration', component: Registration},
    {
        path: '/',
        name: 'Base',
        component: Base,
        redirect: {name: 'Login'},
        meta: {requiresAuth:false},
        children: [
          {path: 'quality', name: 'Quality', component: Quality},
          {path: 'forMotorists', name: 'ForMotorists', component: ForMotorists},
          {path: 'news', name: 'News', component: News},
          {path: 'aboutCompany', name: 'AboutCompany', component: AboutCompany},
          {path: 'login', name: 'Login', component: Login},
          {path: 'registration', name: 'Registration', component: Registration},
        ],
  },
  {
    path: '/personalCabinet',
    name: 'PersonalCabinet',
    component: PersonalCabinet,
    meta: {requiresAuth:true},
    redirect: {name: 'MyCards'},
    children: [
      {path: 'myCards', name: 'MyCards', component: MyCards},
      {path: 'historyOperation', name: 'HistoryOperation', component: HistoryOperation},
      {path: 'aboutProgram', name: 'AboutProgram', component: AboutProgram},
      {path: 'promotions', name: 'Promotions', component: Promotions},
      {path: 'profile', name: 'Profile', component: Profile},
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.state.user.token) {
            next()
            return
        }
        next ({name: 'Login', query: {from: to.path}})
    } else {
        next()
    }
})


export default router
