<template>
    <div class="d-flex sideBarItem align-items-center">
        <img :src="iconSrc" alt="">
        <span class="sideBarItemTitle">
          <slot></slot>
        </span>
    </div>
</template>

<script>
export default {
  name: "SidebarItem",
  props: {
  iconSrc: {
    type: String,
    src: '',
  },
},
  data() {
    return {

    };
  },
  computed: {
  }
}

</script>

<style scoped>
.sideBarItemTitle {
  margin-left: 8px;
}
</style>
