<template>
  <div class="operation d-flex align-items-center justify-content-between">
    <div>
      <div class="placeOperation">{{ getAzsAdress() }}</div>
      <div class="cardOperation">*{{ operation.card.slice(-4) }}</div>
    </div>
    <div class="dateOperation">{{ formatDate(operation.date)}}</div>
    <div v-if="operation.addPoints > 0" class="sumOperation">+{{ operation.addPoints}}</div>
    <div v-else class="sumOperation">-{{ operation.writeoffPoints}}</div>
  </div>
</template>

<script>
export default {
  name: "MyOperation",
  props: {
    operation: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      azsPoints: {
        'office': 'Офис',
        'azs22': 'г. Каменск-Шахтинский, ул. Морская, 66',
        'azs10': 'п. М-Курган, Екатериновка',
        'azs1': 'п. М-Курган, ул. Московская, 142',
        'azs8': 'п. М-Курган, ул. Советская, 16 "А"',
        'azs11': 'г. Таганрог, пер. 1-й Новый, 57',
        'azs5': 'г. Таганрог, пер. 2-й Лодочный, 1/2 "А"',
        'azs24': 'р-н Каменск-Шахтинский, х. Старая Станица, ул. Буденого, 265',
        'azs7': 'с. Николаевка, Федеральная трасса М23',
        'azs15': 'с. Покровское, ул. Привокзальная, 11',
        'azs12': 'с. Самбек, ул. Центральная, 2',
        'azs18': 'г. Таганрог, ул. 2-я Советская, 74 "А"',
        'azs6': 'г. Таганрог, ул. Александровская, 162',
        'azs19': 'г. Таганрог, ул. Котлостроительная, 36',
        'azs4': 'г. Таганрог, ул. Ленина, 175',
        'azs13': 'г. Таганрог, ул. Мариупольское шоссе, 50 "А"',
        'azs20': 'г. Таганрог, ул. Ростовское шоссе, 12',
        'azs25': 'г. Таганрог, ул. Щаденко, 62'
      }
    }
  },
  methods:{
    formatDate(dateString){
      let date = new Date(dateString)
      return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
    },
    getAzsAdress () {
      if (this.azsPoints[this.operation.departmentId]) {
        return this.azsPoints[this.operation.departmentId]
      } else {
        return this.operation.departmentId
      }
    }
  }
}
</script>

<style scoped>
.placeOperation {
  font-weight: 400;
  font-size: 14px;
  line-height: 110%;
  max-width: 180px;
}
.dateOperation {
  font-weight: 400;
  font-size: 14px;
  line-height: 110%;
  opacity: 0.4;
  margin-left: auto;
  margin-right: 24px;
  max-width: 100px;
}
.sumOperation {
  font-weight: 700;
  font-size: 18px;
  line-height: 110%;
  text-align: right;
  color: #2B4049;
  min-width: 6%;
}
.cardOperation {
  margin-right: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 110%;
  opacity: 0.4;
}

/* lg-устройства (больше или равно 992px) */
@media (min-width: 992px) {
  /* CSS для: 992px <= ширины <= 1119px */
  .placeOperation {
    font-weight: 400;
    font-size: 14px;
    line-height: 110%;
    max-width: none;
  }
  .dateOperation {
    margin-left: auto;
    margin-right: 100px;
  }
}

</style>
