<template>
  <div class="loginContainer" @submit.prevent="login">
    <h3 class="loginTitle">Авторизация</h3>
    <MyInput
        type="phone"
        label="Телефон"
        v-model="inputPhone"
        name="phone"
        with-icon
        required
        @keyup.enter="login"
        :errors="errorsPhone"
    ></MyInput>
    <MyInput
        type="password"
        label="Пароль"
        v-model="password"
        name="password"
        with-icon
        required
        @keyup.enter="login"
        :errors="errorsPassword"
    ></MyInput>
    <p v-if="error" class="errorText">Не верно указан логин и/или пароль</p>
    <div class="loginBtnsWrapper d-flex justify-content-between" style="margin-top: 60px">
      <MyButton
          cls="red"
          @click="login"
      >
        <span>Войти</span>
        <span v-if="loading" class="spinner-border spinner-border-sm ms-3" role="status" aria-hidden="true"></span>
      </MyButton>
      <router-link :to="{name: 'Registration'}">
      <MyButton type="submit"
          cls="withBorder">
        Регистрация
      </MyButton>
      </router-link>
    </div>
  </div>
</template>

<script>
import MyInput from "@/components/UI/MyInput";
import MyButton from "@/components/UI/MyButton";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import {global_objects} from "@/global_objects";
export default {
  name: "Login",
  components: {MyButton, MyInput},
  data() {
    return {
      inputPhone: '',
      password: '',
      error: false,
      errorsPassword: [],
      errorsPhone: [],
      loading: false,
    }
  },
  computed:{
    ...mapGetters(['USER'])
  },
  methods: {
    ...mapActions['GET_USER_DETAILS'],
    login: function () {
      let vm = this
      vm.errorsPhone = []
      vm.errorsPassword = []
      // let inputPhone = vm.inputPhone
      // let password = vm.password
      vm.loading = true
      vm.axios.post(
          '/api/v1/login/',
          {
            phone: global_objects.formatPhone(vm.inputPhone),
            password: vm.password
          }
      ).then(response=>{
        console.log(response.data)
        vm.loading = false
        vm.$store.dispatch('SET_USER_TOKEN', response.data.token)
        vm.$store.dispatch('GET_USER_DETAILS')
        if (!vm.$route.query.from) {
          vm.$router.push({name: 'MyCards'})
        } else {
          vm.$router.push(vm.$route.query.from)
        }
      })
      .catch(err=>{
        console.log(err)
        vm.loading = false
        if (err.response.data) {
          if (err.response.data.phone) vm.errorsPhone=err.response.data.phone
          if (err.response.data.password) vm.errorsPassword=err.response.data.password
          vm.error = true
        }
      })
      // this.$store.dispatch('login', { inputPhone, password })
      //     .$store.commit('isAuthenticated',true)
      //     .then(() => this.$router.push('/personalCabinet'))
      //     .catch(err => console.log(err))
    },
  },
}
</script>

<style scoped>

.loginContainer {
  width: 284px;
    margin: 32px auto;
    padding: 0;
}
.withBorder {
  width: 128px;
}
.wrapInput {
  margin: 0 0 24px 0;
  max-width: none;
}
.loginTitle {
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  margin-bottom: 44px;
}
.loginBtnsWrapper {
  margin-top: 60px;
}
.grey, .red {
  max-width: 128px;
}
.withBorder {
  color: #BC0000;
  background-color: transparent;
}


/* lg-устройства (больше или равно 992px) */
@media (min-width: 992px) {
  /* CSS для: 992px <= ширины <= 1119px */
  .loginContainer {
  width: 490px;
  margin: 0 auto;
  padding: 80px 85px;
}
  .withBorder {
    width: 150px;
  }
  .grey, .red {
  max-width: 150px;
}
}

</style>
