<template>
  <div>
    <div class="titleWrap d-flex align-items-center justify-content-between">
      <h2 class="title position-relative">История операций
<!--        <notification :count="lastTransactions.length"/>-->
      </h2>
      <my-button with-margin
                 :icon="require('../../../assets/media/setting.svg')"
                 class="doAction filter"
                 v-if="windowWidth>=992"
                 @click="$refs.filterHistoryOperations.open()">
        Фильтр
      </my-button>
      <my-button :icon="require('../../../assets/media/setting.svg')"
                 class="doAction filter"
                 style="width: 48px; margin-bottom: 0"
                 v-if="windowWidth<992"
                 @click="$refs.filterHistoryOperations.open()">
      </my-button>
    </div>
    <div class="d-flex justify-content-center mt-5" v-if="error">{{error}}</div>
    <div class="operationWrapper" v-if="!error">
      <div class="operations">
        <my-operation v-if="USER.token" v-for="operation in lastTransactions"
                      :operation="operation">
        </my-operation>
      </div>
    </div>
    <div class="titleWrap d-flex align-items-center justify-content-between">
      <h2 class="title position-relative">
      </h2>
      <div v-if="lastTransactions.length>7">
        <my-button
           with-margin
           @click.self="showMore"
           :icon="require('../../../assets/media/arrow-circle-right.svg')"
           cls="doAction"
        >Показать ещё </my-button>
      </div>
    </div>
  </div>
  <my-modal ref="filterHistoryOperations">
    <div class="titleWrap d-flex align-items-center justify-content-between">
      <h2 class="title position-relative">Фильтр истории операций</h2>
    </div>
      <div class="d-flex setPeriodWrapper">
        <my-button @click="setPeriod($event, 1)" cls="setPeriod">
          1 Месяц
        </my-button>
        <my-button @click="setPeriod($event,3)" cls="setPeriod">
          3 Месяца
        </my-button>
        <my-button @click="setPeriod($event,6)" cls="setPeriod">
          6 Месяцев
        </my-button>
        <my-button @click="setPeriod($event,12)" cls="setPeriod">
          1 Год
        </my-button>
      </div>
    <div class="d-flex datePickerWrapper">
      <v-date-picker
          is-expanded
          title-position="left"
          v-model="range" is-range
          :columns="$screens({ default: 1, lg: 2 })"
          :locale="{id:'Ru', masks: { weekdays: 'WW' }}"
      />
<!--      <v-date-picker-->
<!--          title-position="left"-->
<!--          v-model="range" is-range-->
<!--          :columns="$screens({ default: 1, lg: 2 })"-->
<!--          :locale="{id:'Ru', masks: { weekdays: 'WW' }}" />-->
    </div>
    <div class="d-flex filterBtnsWrapper justify-content-between">
      <my-button cls="clearFilter" @click="clearPeriod">
        Сбросить фильтр
      </my-button>
      <div class="d-flex">
        <my-button @click="$refs.filterHistoryOperations.close()"
        class="d-none d-lg-block">
          Закрыть
        </my-button>
        <my-button cls="red" @click="this.loadTransactions(range.start, range.end) ;$refs.filterHistoryOperations.close()">
          Применить
        </my-button>
      </div>
    </div>
  </my-modal>
</template>

<script>
import MyButton from "@/components/UI/MyButton";
import MyOperation from "@/views/personalCabinet/historyOperations/MyOperation";
import MyModal from "@/components/MyModal";
import Notification from "@/components/UI/Notification";
import {mapGetters} from "vuex";

export default {
  name: 'HistoryOperation',
  components: {Notification, MyButton, MyOperation, MyModal},
  data() {
    return {
      date: new Date(),
      range: {
        start: new Date(),
        end: new Date()
      },
      lastTransactions: [],
      screens: {
        tablet: '576px',
        laptop: '992px',
        desktop: '1200px',
      },
      windowWidth: window.outerWidth,
      error: null
    }
  },


  mounted() {
    this.loadTransactions()
    this.$nextTick(() => {
      window.addEventListener('resize', ()=>{this.onResize()});
    })
    let periods = document.getElementsByClassName("setPeriod");
    let actives = document.getElementsByClassName('activeBtn');
    let p;
    for (p = 0; periods.length > p; p++) {
      periods[p].onclick = function() {
        let currentActive = actives[0];
        if (currentActive)
          currentActive.classList.toggle("activeBtn");

        if (currentActive !== this)
          this.classList.toggle("activeBtn");
      };
    }
  },
  onUnmounted() {
    window.removeEventListener('resize', ()=>{this.onResize()});
  },
  computed:{
    ...mapGetters(['USER'])
  },
  methods: {
    onResize(){
      this.windowWidth = window.outerWidth
    },
    showMore(event){
      event.target.classList.toggle('d-none')
      let to = new Date()
      let from = new Date(now.getFullYear()-1, now.getMonth(), now.getDate())
      this.lastTransactions()
    },
    clearPeriod() {
      let actives = document.getElementsByClassName('activeBtn');
      let currentActive = actives[0];
      this.range = {
        start: new Date(),
        end: new Date()
      }
      currentActive.classList.remove("activeBtn");
    },

    setPeriod(event, monthCount){
      this.range = {
        start: new Date().setMonth(this.date.getMonth()-monthCount),
        end: new Date()
      }
    },
    loadTransactions(from = new Date(new Date().getFullYear(), new Date().getMonth()-1, new Date().getDate()), to = new Date()){
      this.error = null
      let vm = this
      const sortByDate = (t1, t2) => (new Date(t1.date) > new Date(t2.date)) ? 1 : -1
      this.axios.get(
          `/api/v1/get_transactions?startDate=${from.toISOString()}&endDate=${to.toISOString()}`,
          {headers:{'Authorization': `Token ${vm.USER.token}`}}
      )
      .then(response=>{
        vm.error = null
        console.log(response.data)
        let userData = response.data.userData
        let lastTransactions = userData.reduce((sum, el)=>{
          return sum.concat(el.transactions.map(t=>{
            let res = t
            t.card = el.number
            return res

          }))
        }, []).sort(sortByDate)
        vm.lastTransactions = lastTransactions.reverse()
        console.log(lastTransactions)
      })

      .catch(error=>{
        console.log(error)
        if (error.response.data) {
          console.log('mnvbssdfshfksjdhfksjhfk', error.response.data.message)
          vm.error = error.response.data.message
        }
      })
    }
  }
}
</script>
<style scoped>
.btnIcon {
  transform: rotate(90deg);
}
.operations {
  /*max-height: 460px;*/
  /*overflow: hidden;*/
}

.filterBtnsWrapper {
  margin: 48px 0;
}
.red {
  margin-left: 16px;
}
.activeBtn{
  background-color: #BC0000;
  color: #FFFFFF;
}
.title {
  margin: 0;
}
  .setPeriodWrapper {
    margin-top: 18px;
    overflow: hidden;
  }
/* lg-устройства (больше или равно 992px) */
@media (min-width: 992px) {
  /* CSS для: 992px <= ширины <= 1119px */
  .setPeriodWrapper {
    padding-left: 32px;
  }
  .datePickerWrapper {
  padding: 0 32px 32px 32px;
  }
  .filterBtnsWrapper {
  padding: 0 36px;
}
}

</style>
