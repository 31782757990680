<template>
  <div class="loginContainer" v-if="enterCode" >
    <h3 class="loginTitle">Регистрация</h3>
    <MyInput
        type="text"
        label="Телефон"
        v-model="inputPhone"
        name="phone"
        with-icon
        :errors="errorsPhone"
    ></MyInput>
    <MyInput
        type="email"
        label="E-mail"
        v-model="inputEmail"
        name="email"
        with-icon
        :errors="errorsEmail"
    ></MyInput>
    <MyInput
        type="password"
        label="Пароль"
        v-model="password1"
        name="password1"
        with-icon
        :errors="errorsPassword1"
    ></MyInput>
    <MyInput
        type="password"
        label="Подтверите пароль"
        v-model="password2"
        name="password2"
        with-icon
        :errors="errorsPassword2"
    ></MyInput>
    <div class="loginBtnsWrapper d-flex justify-content-between" style="margin-top: 60px">
      <MyButton @click="$router.push({name: 'Login'})">
        Назад
      </MyButton>
      <MyButton @click="registration" id="btnConfirmReg" cls="red">
        <span>Продолжить</span>
      <span v-if="loading" class="spinner-border spinner-border-sm ms-1 p-2" role="status" aria-hidden="true"></span>
      </MyButton>
    </div>
  </div>
  <div class="loginContainer" v-else>
    <h3 class="loginTitle">Код авторизации</h3>
<!--    <div class="description">-->
<!--      Укажите 4 последние цифры номера телефона c <br>-->
<!--      с которого Вам только что звонили-->
<!--    </div>-->
    <MyInput
        type="text"
        label="Номер телефона уазанный вами"
        name="phone"
        disabled
        :placeholder="inputPhone"
    ></MyInput>
    <MyInput
        label="Укажите 4 последние цифры номера телефона c
      с которого Вам только что звонили"
        name="phoneCode"
        :errors="errorsPhoneCode"
        v-model="phoneCode"
    ></MyInput>
    <Timer
        :date=refreshTimer()
        @sendCode="registration"
    ></Timer>
    <div class="loginBtnsWrapper d-flex justify-content-between" style="margin-top: 60px">
      <MyButton @click="enterCode=!enterCode, loading=false">
        Назад
      </MyButton>
      <MyButton cls="red" @click="confirmPhoneCode()"> Продолжить
        <span v-if="loading" class="spinner-border spinner-border-sm ms-1 p-2" role="status" aria-hidden="true"></span>
      </MyButton>
    </div>
  </div>
</template>

<script>
import MyInput from "@/components/UI/MyInput";
import MyButton from "@/components/UI/MyButton";
import axios from "axios";
import Timer from "@/views/Timer";
import {global_objects} from "@/global_objects";
export default {
  name: "Registration",
  components: {MyButton, MyInput, Timer},
  data() {
    return {
      inputPhone: '',
      inputEmail: '',
      inputCode: '',
      enterCode: true,
      password1: '',
      password2: '',
      phoneCode: '',
      error: false,
      loading: false,
      errorsPassword1: [],
      errorsPassword2: [],
      errorsPhone: [],
      errorsEmail: [],
      errorsPhoneCode: []
    }
  },
  methods: {
    // sendCode() {
    //   this.enterCode = true
    //   return this.enterCode
    // },
    refreshTimer() {
      this.date = new Date(Date.now()+120000)
      return this.date
    },
    registration() {
      let vm = this
      vm.errorsPhone = []
      vm.errorsEmail =[]
      vm.errorsPassword1 = []
      vm.errorsPassword2 = []
      if (!vm.loading) {
        vm.loading = true
        console.log('global_objects.formatPhone(vm.inputPhone)', global_objects.formatPhone(vm.inputPhone))
        vm.axios.post(
            '/api/v1/sign_up/',
            {
              phone: global_objects.formatPhone(vm.inputPhone),
              email: vm.inputEmail,
              password1: vm.password1,
              password2: vm.password2,
            }
        ).then(response => {
          vm.enterCode = false
          vm.loading = false
          console.log(response.data)

        })
            .catch(err => {
              console.log(err)
              if (err.response.data) {
                if (err.response.data.phone) vm.errorsPhone = err.response.data.phone
                if (err.response.data.email) vm.errorsEmail = err.response.data.email
                if (err.response.data.password1) vm.errorsPassword1 = err.response.data.password1
                if (err.response.data.password2) vm.errorsPassword2 = err.response.data.password2
                if (err.response.data.non_field_errors) vm.errorsPassword1 = err.response.data.non_field_errors
                if (err.response.data.message) vm.errorsPhone = [err.response.data.message]
                vm.error = true
                vm.loading = false
              }
            })
        // this.$store.dispatch('login', { inputPhone, password })
        //     .$store.commit('isAuthenticated',true)
        //     .then(() => this.$router.push('/personalCabinet'))
      }//     .catch(err => console.log(err))
    },
    confirmPhoneCode(){
      let vm = this
      vm.errorsPhoneCode = []
      vm.loading = true

      vm.axios.post(
          '/api/v1/confirm_phone/',
          {
            phone: vm.inputPhone,
            code: vm.phoneCode,
          }
      ).then(response=>{
        console.log(response.data)
        vm.loading = false
        vm.$store.dispatch('SET_USER_TOKEN', response.data.token)
        vm.$store.dispatch('GET_USER_DETAILS')
        if (!vm.$route.query.from) {
          vm.$router.push({name: 'MyCards'})
        } else {
          vm.$router.push(vm.$route.query.from)
        }
      })
          .catch(err=>{
            console.log(err)
            if (err.response.data) {
              if (err.response.data.message) vm.errorsPhoneCode=err.response.data.message
              vm.error = true
              vm.loading = false
            }
          })
    },
  },
}
</script>

<style scoped>

.loginContainer {
  width: 284px;
    margin: 32px auto;
    padding: 0;
}

.wrapInput {
  margin: 0 0 24px 0;
  max-width: none;
}
.loginTitle {
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  margin-bottom: 44px;
}
.loginBtnsWrapper {
  margin-top: 60px;
}
.grey, .red {
  max-width: 136px;
}
.withBorder {
  color: #BC0000;
  background-color: transparent;
}



/* lg-устройства (больше или равно 992px) */
@media (min-width: 992px) {
  /* CSS для: 992px <= ширины <= 1119px */
  .loginContainer {
  width: 490px;
  margin: 0 auto;
  padding: 80px 85px;
}
  .grey, .red {
  max-width: 150px;
}
}
</style>
