export const global_objects = {
    getMediaUrl: (path) => {
        // return (process.env.NODE_ENV === 'development') ? 'http://shaker.ru'+path : path;
        return (process.env.NODE_ENV === 'development') ? 'https://kobart.cloudsockets.net'+path : path;
    },
    formatPhone: (phone) => {
        phone = phone.replaceAll('-', '');
        phone = phone.replaceAll('(', '');
        phone = phone.replaceAll(')', '');
        phone = phone.replaceAll('+', '');
        phone = phone.replaceAll(' ', '');
        if (phone.slice(0, 2) === '89') {
            phone = '79' + phone.slice(2)
        }
        if (phone.slice(0, 2) === '79') {
            phone = '79' + phone.slice(2)
        }
        return phone
    }
}