<template>
  <div style="padding-bottom: 34px;display: flex;flex-direction: column">
    <div class="navWrapper col
    d-flex flex-column flex-lg-row justify-content-between align-items-center">
      <div class="logoRed">
      </div>
      <div class="navLinks justify-content-between">
        <a target="_blank" href="http://kobart.ru/">Главная</a>
        <a target="_blank" href="http://kobart.ru/quality/tank-farm">Качество</a>
        <a target="_blank" href="http://kobart.ru/drivers/discount-card">Автомобилистам</a>
        <a target="_blank" href="http://kobart.ru/news-and-actions">Новости</a>
        <a target="_blank" href="http://kobart.ru/about-company/gruppa-kompanij-kobart">О компании</a>
<!--        <router-link to="/">-->
<!--          Главная-->
<!--        </router-link>-->
<!--        <router-link :to="{name: 'Quality'}">-->
<!--          Качество<span class="dropdown"></span>-->
<!--        </router-link>-->
<!--        <router-link :to="{name: 'ForMotorists'}">-->
<!--          Автомобилистам<span class="dropdown"></span>-->
<!--        </router-link>-->
<!--        <router-link :to="{name: 'News'}">-->
<!--          Новости<span class="dropdown"></span>-->
<!--        </router-link>-->
<!--        <router-link :to="{name: 'AboutCompany'}">-->
<!--          <div class="dropdown">-->
<!--            <button class="dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">-->
<!--              О компании-->
<!--            </button>-->
<!--            <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">-->
<!--              <li><a target="_blank" href="http://kobart.ru/about-company/contacts" class="dropdown-item" type="button">Контакты</a></li>-->
<!--              <li><button class="dropdown-item" type="button">Адреса АЗС</button></li>-->
<!--              <li><button class="dropdown-item" type="button">Группа компаний Кобарт</button></li>-->
<!--              <li class="dropdown-submenu">-->
<!--                <button class="dropdown-item" type="button">-->
<!--                  Строительство-->
<!--                </button>-->
<!--              <ul class="dropdown-menu">-->
<!--              <li><a class="dropdown-item" tabindex="-1" href="#">ЖК Чайка в Ялте</a></li>-->
<!--              <li><a class="dropdown-item" href="#">ЖК на ул. М. Жукова</a></li>-->
<!--            </ul>-->
<!--              </li>-->
<!--              <li><button class="dropdown-item" type="button">Футбольный клуб Кобарт</button></li>-->
<!--              <li><button class="dropdown-item" type="button">Ряженская мельница</button></li>-->
<!--              <li class="dropdown-submenu">-->
<!--                <button class="dropdown-item" type="button">-->
<!--                  Партнёрам-->
<!--                </button>-->
<!--              <ul class="dropdown-menu">-->
<!--              <li><a class="dropdown-item" tabindex="-1" href="#">Для предприятий</a></li>-->
<!--              <li><a class="dropdown-item" href="#">Оптовые продажи</a></li>-->
<!--            </ul>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->
<!--        </router-link>-->
      </div>
        <router-link :to="{name: 'Profile'}"
                     v-if="USER.token"
                     class="profile sideBarLink d-none d-lg-flex align-items-center">
          <div class="navAvatar" :style="{ backgroundImage: 'url('+ getPicture() +')'}">
          </div>
          <div class="userFullName d-flex flex-column">
            <div class="navUserName">
              {{ USER.name }}
            </div>
            <div class="navUserSurname">
              {{ USER.surname }}
            </div>
          </div>
        </router-link>
      <div v-else class="d-flex align-items-center">
        <div class="d-flex flex-column">
          <MyButton
              cls="red"
              @click="$router.push({name: 'Login'})">
            Войти
          </MyButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import MyButton from "@/components/UI/MyButton";
import {mapGetters} from "vuex";

export default {
  name: "NavBar",
  components: {MyButton},
  computed:{
    ...mapGetters(['USER'])
  },
  methods: {
    getPicture(){
      return this.USER.avatar? this.glb.getMediaUrl(this.USER.avatar): require('../assets/media/defaultAvatar.svg');
    },
  },
}
</script>

<style>
a {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
  text-decoration: none;
}
a:hover {
  color: #000000;
  text-decoration: none;
}
.logoRed {
  background-image: url("../assets/media/logoRed.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 110px;
  height: 32px;
  /*margin-left: 40px;*/
  /*margin-right: auto;*/
}
.navWrapper {
  padding: 36px 0 26px 0;
}
.dropdown {
  position: relative;
}
.dropdown:after {
  position: absolute;
  right: -12px;
  top: 9px;
  content: '';
  border-top: 3px solid rgb(255, 255, 255);
  border-right: 3px solid transparent;
  border-left: 3px solid transparent;
  z-index: 1001;
}
.dropdown:before {
  position: absolute;
  right: -14px;
  top: 9px;
  content: '';
  border-top: 5px solid rgb(0, 0, 0);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  z-index: 1000;
}
.navAvatar {
  /*background-image: url("../assets/media/avatar.jpg");*/
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 16px;
}
/*.subNav {*/
/*  background: #F9F9FC;*/
/*  border-radius: 6px;*/
/*  padding: 7px 0;*/
/*}*/
h1 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}


/* lg-устройства (больше или равно 992px) */
@media (min-width: 992px) {
  /* CSS для: 992px <= ширины <= 1119px */
  .logoRed {
    margin-right: 0;
  }
  /*.subNav {*/
  /*  background: #F9F9FC;*/
  /*  border-radius: 6px;*/
  /*  padding: 7px 7px 7px 32px;*/
  /*}*/
}
</style>
