<template>
  <div>
    <div class="titleWrap d-flex align-items-center justify-content-between">
      <h2 class="title position-relative">Мои карты
        <notification :count="USER.cards.length"></notification>
      </h2>
<!--      <my-button with-margin-->
<!--                 class="d-none d-lg-flex"-->
<!--                 :icon="require('../../../assets/media/add-circle.svg')"-->
<!--                 cls="doAction">-->
<!--        Добавить карту-->
<!--      </my-button>-->
    </div>
    <div class="cardsWrapper position-relative">
      <div class="cards d-flex">
        <my-card
           v-if="USER.token"
           v-for="card in USER.cards"
           :card="card">
        </my-card>
      </div>
    </div>
<!--    <my-button with-margin-->
<!--               class="d-lg-none"-->
<!--               :icon="require('../../../assets/media/add-circle.svg')"-->
<!--               cls="doAction">-->
<!--      Добавить карту-->
<!--    </my-button>-->
    <div class="titleWrap d-flex align-items-center justify-content-between">
      <h2 class="title position-relative">Последние операции
<!--        <notification :count="lastTransactions.length"></notification>-->
      </h2>
      <router-link :to="{name: 'HistoryOperation'}">
        <my-button with-margin
                   class="d-none d-lg-flex"
                   :icon="require('../../../assets/media/arrow-circle-right.svg')"
                   cls="doAction">
          Все операции
        </my-button>
      </router-link>
    </div>
    <div class="d-flex justify-content-center mt-5" v-if="error">{{error}}</div>
    <div class="operationWrapper">
      <div class="operations">
        <my-operation v-if="USER.token" v-for="transaction in lastTransactions"
                      :operation="transaction">
        </my-operation>
      </div>
    </div>
    <router-link :to="{name: 'HistoryOperation'}">
      <my-button with-margin
                 class="d-lg-none"
                 :icon="require('../../../assets/media/arrow-circle-right.svg')"
                 cls="doAction">
        Все операции
      </my-button>
    </router-link>
  </div>
</template>

<script>

import MyButton from "@/components/UI/MyButton";
import Notification from "@/components/UI/Notification";
import MyCard from "@/views/personalCabinet/myCards/MyCard";
import MyOperation from "@/views/personalCabinet/historyOperations/MyOperation";
import {mapGetters} from "vuex";
export default {
  name: 'MyCards',
  components: {MyOperation, MyCard, Notification, MyButton},
  data() {
    return {
      lastTransactions: [],
      error: null
    }
  },
  mounted() {
    this.loadTransactions()
  },
  computed:{
    ...mapGetters(['USER'])
  },
  methods: {
    loadTransactions(from = new Date(new Date().getFullYear(), new Date().getMonth()-1, new Date().getDate()), to = new Date()){
      let vm = this
      const sortByDate = (t1, t2) => (new Date(t1.date) > new Date(t2.date)) ? 1 : -1
      this.axios.get(
          `/api/v1/get_transactions`,
          {headers:{'Authorization': `Token ${vm.USER.token}`}}
      )
      .then(response=>{
        vm.error = null
        console.log(response.data)
        let userData = response.data.userData
        let lastTransactions = userData.reduce((sum, el)=>{
          return sum.concat(el.transactions.map(t=>{
            let res = t
            t.card = el.number
            return res
          }))
        }, []).sort(sortByDate)
        vm.lastTransactions = lastTransactions.reverse()
        console.log(lastTransactions)
      })
      .catch(error=>{
        vm.error = error.response.data.message
        console.log(error)
      })
    },

  }
}
</script>
<style scoped>
.title {
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  margin: 0;
}
.cards {
  position: absolute;
  overflow: hidden;
  height: 172px;
}
.operations {
  max-height: 272px;
  overflow: hidden;
}
</style>
