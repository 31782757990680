<template>
  <transition name="fade">
    <div class="popup-modal" v-show="isVisible" @click.self="close">
      <div :class="['window', 'position-relative', cls]">
        <div class="window-content">
        <div v-if="!forbidClose"
            class="position-absolute fs-3"
            style=" top: 0.3rem; right: 1rem; z-index: 150"
            @click="close">
          <img src="../assets/media/cross.png" alt="">
        </div>
        <div class="pt-5 pb-5">
          <slot></slot>
        </div>
      </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'MyModal',

  data: () => ({
    isVisible: false
  }),
  props: {
    forbidClose: {
      type: Boolean,
      default: false
    },
    cls: {
      type: String,
      validator: value => {
        return [
          'fullScreen',
        ].includes(value)
      }
    },
  },
  methods: {
    open() {
      this.isVisible = true
    },

    close() {
      if (!this.forbidClose) {
        this.isVisible = false
      } else {
        console.log('запрещено закрывать, почта не подтверждена')
      }
    },
  },
  computed: {
  }
}
</script>
<style scoped>
.popup-modal {
  background-color: rgb(0 0 0 / 70%);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  align-items: center;
  z-index: 1100;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.window {
  position: relative;
  border-radius: 1rem;
  max-width: max-content;
  padding: 0.75rem;
  margin: 1.75rem auto;
  background-color: #fff;
  box-shadow: 0px 0px 24px -4px rgb(0 0 0 / 20%);
}
.window-content{
  height: 100%;
}
.fullScreen {
  height: fit-content;
  max-width: 320px;
  text-align: center;
}
</style>
