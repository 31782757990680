<template>
  <div class="card" :style="card.balance <=0?'background-color: red':''">
    <span class="balance">{{ card.balance }}</span>
    <span class="number">{{ card.number }}</span>
  </div>
</template>

<script>
export default {
  name: "MyCard",
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
.card {
  width: 286px;
  padding: 32px;
  background: #2B4049;
  border-radius: 12px;
  margin-right: 14px;
}
.balance {
  font-weight: 700;
  font-size: 64px;
  line-height: 64px;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 20px;
}
.number {
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.185em;
  color: #FFFFFF;
}
</style>