<template>
  <footer class="footer align-items-center">
    <div class="container">
      <div class="row d-flex justify-content-around justify-content-lg-between align-items-center">
        <div class="col-6 col-lg-1 logo-white"></div>
        <div class="d-none d-lg-block col-lg-2 address">347942, Ростовская
          область, г. Таганрог,
          ул. Бакинская, 27
        </div>
        <div class="d-none d-lg-block col-lg-2 phones">
          <div class="phone">8 (928) 7 688 000</div>
          <div class="phone">8 (8634) 322 703</div>
          <div class="phone">8 (8634) 322 543</div>
        </div>
        <div class="d-none d-lg-block col-lg-2 places">
          <div class="d-flex align-items-center">
            <div class="arrow"></div>
            <div class="place">ТРЦ “Арбуз”</div>
          </div>
          <div class="d-flex align-items-center">
            <div class="arrow"></div>
            <div class="place">ФК “Кобарт”</div>
          </div>
        </div>
        <div class="col-6 col-lg-2 links d-flex justify-content-end">
          <a target="_blank" href="https://vk.com/azs_kobart"><div class="linkVK"></div></a>
          <a target="_blank" href="https://t.me/+79287688000"><div class="linkTelegram"></div></a>
        </div>
      </div>
      <div class="row mt-4 d-flex justify-content-around justify-content-lg-between align-items-center">
        <div class="col-6 d-lg-none address">347942, Ростовская
          область, г. Таганрог,
          ул. Бакинская, 27
        </div>
        <div class="col-6 d-lg-none phones text-end">
          <div class="phone">8 (928) 7 688 000</div>
          <div class="phone">8 (8634) 322 703</div>
          <div class="phone">8 (8634) 322 543</div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MainFooter",
}
</script>

<style scoped>
footer {
  background-color: #292D32;
  color: #FFFFFF;
  font-size: 12px;
  line-height: 148%;
  padding: 42px 34px;
  margin-top: 64px;
}
.logo-white {
  background-image: url("../assets/media/logo-white.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 110px;
  height: 32px;
  margin-right: 0px;
}
.linkVK {
  background-image: url("../assets/media/VK.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 32px;
  height: 32px;
}
.linkTelegram {
  background-image: url("../assets/media/Telegram.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 32px;
  height: 32px;
  margin-left: 12px;
}
.arrow {
  background-image: url("../assets/media/arrow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 10px;
  height: 10px;
  margin-right: 4px;
}
</style>
