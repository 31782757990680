<template>
  <div>
    about Company
  </div>
</template>

<script>
export default {
  name: "AboutCompany"
}
</script>

<style scoped>

</style>