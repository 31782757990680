<template>
  <div v-if="count" class="notification position-absolute">
    {{ count }}
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Notification",
  props: {
    count: {
      type: Number,
      required: true
    }
  }
}
</script>

<style scoped>
.notification {
  background-color: #C4C4C4;
  border-radius: 50%;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  height: 24px;
  width: 24px;
  top: 6px;
  right: -34px;
  padding: 2px 3px 0px 2px;
}
</style>