<template>
  <div>About Program</div>
</template>

<script>
export default {
  name: "AboutProgram"
}
</script>

<style scoped>

</style>