<template>
  <div>
    <router-view/>
  </div>
</template>

<script>
import {useStore} from "vuex";

export default {
  name: "App",
  setup () {
    const store = useStore();
    if (store.state.user.token){
      store.dispatch('GET_USER_DETAILS')
    }
  },
}
</script>