<template>
  <div class="container" v-if="USER.token">
    <nav-bar class="d-none d-lg-block"/>
      <div class="col subNav d-none d-lg-flex justify-content-between align-items-center">
        <h1>Личный кабинет пользователя</h1>
        <my-button @click="logout">
          Выход
        </my-button>
      </div>
      <div class="hamburgerHeader d-lg-none d-flex justify-content-between align-items-center">
        <Slide :width="windowWidth.toString()">
          <nav-bar></nav-bar>
        </Slide>
        <div class="logoRed">
        </div>
        <router-link :to="{name: 'Profile'}"
                     v-if="USER.token"
                     class="profile sideBarLink d-flex d-lg-none align-items-center">
          <div class="navAvatar" :style="{ backgroundImage: 'url('+ USER.avatar +')'}">
          </div>
          <div class="userFullName d-flex flex-column">
            <div class="navUserName">
              {{ USER.name }}
            </div>
            <div class="navUserSurname">
              {{ USER.surname }}
            </div>
          </div>
        </router-link>
      </div>
      <div class="col subNav d-flex d-lg-none justify-content-between align-items-center">
        <h1>Личный кабинет пользователя</h1>
        <my-button cls="arrow"
                   :class="visible? 'flip': ''"
                   :icon="require('../../assets/media/arrow_down.svg')"
                   v-on:click="visible=!visible">
        </my-button>
      </div>
      <div class="row">
<!--        <SideBar v-show="windowWidth>=992"/>-->
        <transition name="collapse">
        <SideBar
            v-if="windowWidth<=992?visible:!visible"
            @collapse-menu="visible=false"
        />
        </transition>
        <div class="col-lg-9" style="min-height: calc(100vh - 312px)">
          <router-view></router-view>
        </div>
      </div>
  </div>
  <div class="container-fluid p-0">
    <main-footer></main-footer>
  </div>
  <VerifyEmailModal ref="emailVerificationModal"/>
</template>

<script>
import NavBar from "@/components/NavBar";
import MainFooter from "@/components/MainFooter";
import SideBar from "@/components/SideBar";
import { Slide } from 'vue3-burger-menu';
import {mapGetters, mapActions} from "vuex";
import MyButton from "@/components/UI/MyButton";
import Login from "@/views/personalCabinet/Login";
import MyModal from "@/components/MyModal";
import VerifyEmailModal from "@/views/personalCabinet/VerifyEmailModal";

export default {
  name: "PersonalCabinet",
  components: {VerifyEmailModal, MyModal, Login, NavBar, MainFooter, SideBar, MyButton, Slide},
  computed:{
    ...mapGetters(['USER']),
  },
  methods: {
    onResize(){
      this.windowWidth = window.innerWidth
    },
    ...mapActions([
      'GET_USER_DETAILS',
    ]),
    logout() {
      this.$store.dispatch('LOGOUT')
      this.$router.push('/login')
    }
  },

  data: function (){
    return {
      visible: false,
      windowWidth: window.innerWidth
    }
  },
  mounted() {
    this.$store.dispatch('GET_USER_DETAILS_PROMISE').then(()=>{
      if (!this.$store.state.user.isEmailVerified){
        this.$refs.emailVerificationModal.open()
      }
    })
    this.$nextTick(() => {
      window.addEventListener('resize', ()=>{this.onResize()});
    })
  },

  onUnmounted() {
    window.removeEventListener('resize', ()=>{this.onResize()});
  },

}
</script>

<style scoped>
.flip {
  transform: rotate(180deg);
}
.hamburgerHeader {
  margin-top: 20px;
}
</style>