<template>
  <div>
    <div class="titleWrap d-flex align-items-center justify-content-between">
      <h2 class="title position-relative">Профиль пользователя
      </h2>
      <div></div>
    </div>
    <div class="d-flex flex-column flex-lg-row justify-content-between" style="margin-bottom: 64px">
      <div class="col-12 col-md-5 avatarPreviewWrapper">
        <ChangePicture
            v-model="inputAvatar"
            :picture="USER.avatar"
            class="avatarPreview">
        </ChangePicture>
        <div class="description">
          Нажмите, что бы загрузить или изменить фото профиля.
        </div>
      </div>
      <div class="col-12 col-lg-6 formWrapper">
        <div class="inputs">
          <MyInput
              type="text"
              label="Имя"
              v-model="inputName"
              name="name"
              disabled
              :placeholder="USER.name"
          ></MyInput>
          <MyInput
              type="text"
              label="Фамилия"
              v-model="inputSurName"
              name="surName"
              disabled
              :placeholder="USER.surname"
          ></MyInput>
          <MyInput
              type="text"
              label="Телефон"
              v-model="inputPhone"
              name="phone"
              disabled
              :placeholder="USER.phone"
          ></MyInput>
          <MyInput
              type="text"
              label="E-mail"
              v-model="inputEmail"
              name="email"
              disabled
              :placeholder="USER.email"
          ></MyInput>
          <div class="description" v-if="!USER.isEmailVerified">
            Ваш почтовый ящик не подтвержден, мы отправили
            письмо на указанный вами почтовый ящик.
          </div>
          <div class="description">
            В данной форме Вы можете добавить/редактировать аватар либо подписаться на уведомления.
            Если Вы хотите изменить другие данные, то свяжитесь с оператором.
          </div>
          <div v-on:click="this.checked=!checked" class="align-self-center text-center d-flex check">
            <input
                 class="form-check-input"
                 type="checkbox"
                 v-model="USER.checked"
                 ref="getNotificationsCheckbox"
            >
            <div class="checkText">
              Я хочу получать уведомления
            </div>
          </div>
        </div>
        <my-button with-margin
                   cls="red"
                   @click="handleEditSubmit">
          Сохранить изменения
        </my-button>
        <my-modal cls="fullScreen" ref="notificationModalSuccess">
          <h1>Изменения сохранены</h1>
          <div class="description">
            В данной форме Вы можете добавить/редактировать аватар либо подписаться на уведомления.
            Если Вы хотите изменить другие данные, то свяжитесь с оператором.
          </div>
        </my-modal>
        <my-modal cls="fullScreen" ref="notificationModalError">
          <h1>Что-то пошло не так</h1>
          <div class="description">
            Повторите попыку позже, либо обратитесь к администратору
          </div>
        </my-modal>
      </div>
    </div>
  </div>
</template>

<script>
import MyInput from "@/components/UI/MyInput";
import MyButton from "@/components/UI/MyButton";
import MyModal from "@/components/MyModal";
import {mapActions, mapGetters} from "vuex";
import ChangePicture from "@/views/personalCabinet/profile/ChangePicture";
export default {
  name: "Profile",
  components: {ChangePicture, MyInput, MyButton, MyModal},
  data() {
    return {
      inputName: '',
      disabled: false,
      inputSurName: '',
      inputPhone: '',
      checked: true,
      inputAvatar: '',
      inputEmail: '',
      placeholder: '',
      file: ''
    }
  },
  computed:{
    ...mapGetters(['USER'])
  },
  methods: {
    ...mapActions(['GET_USER_DETAILS_PROMISE']),
    handleEditSubmit: async function() {
      let formData = new FormData()
      // formData.append('file', this.file)
      if(this.inputAvatar) {
        formData.set('avatar', this.inputAvatar)
      }
      // if(this.checked) {
        formData.set('wants_recive_notes', this.$refs.getNotificationsCheckbox.checked)
      // }
      if(formData) {
        const vm = this;
        this.axios.post(
            `/api/v1/save_settings/`,
            formData,
            {headers:{'Authorization': `Token ${vm.USER.token}`},

            })
            .then(function () {
              vm.GET_USER_DETAILS_PROMISE().then(()=>vm.$refs.notificationModalSuccess.open())
            })
            .catch(function (error) {
              vm.$refs.notificationModalError.open()
            });
      } else {
        console.log('NO FORM DATA')
      }
    },
  },
}
</script>

<style scoped>
.avatarPreviewWrapper {
  padding-left: 0;
  max-width: 28rem;
  margin: 0 auto;
}
.avatarPreview {
  width: 100%;
  height: 255px;
  border-radius: 6px;
  background-image: url(../../../assets/media/defaultAvatar.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-top: 12px;
}
.fullScreen {

}
.description {
  font-size: 12px;
  line-height: 18px;
  color: #2B4049;
  margin-top: 24px;
  width: 100%;
  margin-bottom: 40px;
}
.check {
  margin-top: 32px;
  margin-bottom: 60px;
}
.checkText {
  margin-left: 12px;
  font-size: 15px;
  line-height: 22px;
  color: #2B4049;
}
.form-check-input[type=checkbox]{
  height: 24px;
  width: 24px;
  border-radius: 4px;
  border: 2px solid #BC0000;
  margin: 0;
}
.form-check-input:checked[type=checkbox] {
  border-radius: 4px;
  background-image: url("../../../assets/media/checkArrow.svg");
  background-color: transparent;
  background-size: 80%;
  background-position: center;
}
.form-check-input:focus {
  outline: 0;
  box-shadow: none;
}
.red {
  margin-left: 0px;
}


/* lg-устройства (больше или равно 992px) */
@media (min-width: 992px) {
  /* CSS для: 992px <= ширины <= 1119px */
  .avatarPreviewWrapper {
    padding-left: 32px;
  }
  .avatarPreview {
    width: 255px;
    margin-top: 30px;
  }
}
</style>
