<template>
  <div class="container">
    <nav-bar class="d-none d-lg-block"/>
    <div class="hamburgerHeader d-lg-none d-flex justify-content-between align-items-center">
      <Slide :width="windowWidth.toString()">
        <nav-bar></nav-bar>
      </Slide>
      <div class="logoRed">
      </div>
    </div>
    <div style="min-height: calc(100vh - 314px)">
      <router-view></router-view>
    </div>
  </div>
  <div class="container-fluid p-0">
    <main-footer></main-footer>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import { Slide } from 'vue3-burger-menu';
import MainFooter from "@/components/MainFooter";
import {mapGetters} from "vuex";
import Login from "@/views/personalCabinet/Login";

export default {
  name: "Base",
  components: {Login, NavBar, MainFooter, Slide},
  computed:{
    ...mapGetters(['USER'])
  },
  data: function (){
    return {
      windowWidth: window.innerWidth
    }
  },
}
</script>

<style>

</style>
