<template>
  <div class="wrapInput">
    <div class="d-flex justify-content-between position-relative" ref="divEl">
      <div><label class="" v-if="label" ref="labelEl">{{ label }}</label></div>
    </div>
    <div class="position-relative">
      <input
          ref="input"
          :type="type"
          :maxlength="maxlength"
          :max="type==='number'&& max ? max : undefined"
          :autocomplete="autocomplete"
          :placeholder=placeholder
          :pattern="pattern"
          :required="required"
          @input="updateInput"
          :value="modelValue"
          :disabled=disabled
          :class="{'error':errors.length}"
          :data-tel-input="phoneInput?true:undefined"
          :name="name"
          :id="name"
      >
      <div class="editIcon position-absolute" v-if="withIcon"></div>
      <div v-if="errors.length">
        <p v-for="(item, index) in errors" :key="index" class="errorText">{{ item }}</p>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Input",
  props: {
    label: {
      type: String,
      default: "",
      required: false
    },
    placeholder: {
      type: String,
      default: "",
      required: false
    },
    type: {
      type: String,
      default: "text"
    },
    max:{
      type: String,
      default: undefined
    },
    maxlength: {
      type: String,
      default: undefined
    },
    autocomplete: {
      type: String,
      default: undefined
    },
    pattern:{
      type: String,
      default: undefined
    },
    required: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: String,
      required: true
    },
    withIcon: {
      type: Boolean,
      default: false
    },
    phoneInput: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default: []
    },
    name:{
      type: String,
      required: true
    }
  },

  data:() =>({
      input: '',
      topRight: '250px',
      topLeft: '150px'
  }),
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value)
    },
    focus(){
      this.$refs.input.focus()
    }
  },
}
</script>

<style scoped>
input {
  font-size: 15px;
  line-height: 22px;
  color: #2B4049;
  align-items: center;
  width: 100%;
  background: #F9F9FC;
  border-radius: 4px;
  padding: 8px 48px 8px 8px;
  border: none;
  z-index: 2;
}
input:autofill,
input:-internal-autofill-selected,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  color: #fff !important;
  background: transparent !important;
  border: 0.094rem solid #fff !important;
}
.wrapInput {
  margin: 0 auto 1.75rem;
  max-width: 28rem;
}
input:active, :hover, :focus {
  outline: 0;
  outline-offset: 0;
}
input::placeholder,
input::-webkit-input-placeholder {
  color: #000000;
  font-size: 14px;
  line-height: 160%;
}
label {
  font-size: 14px;
  line-height: 160%;
  color: #000000;
  margin-bottom: 8px;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.editIcon {
  background-image: url("../../assets/media/edit.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 24px;
  height: 24px;
  top: 8px;
  right: 12px;
}
.error {
  border: 1px solid #BC0000;
}
.errorText {
  color: #BC0000;
}
</style>