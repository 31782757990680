import { createStore } from 'vuex'
import axios from "axios";

export default createStore({
  state: {
    user: {
      token: localStorage.getItem('token'),
      cards: [],
      operations: [],
      avatar: ''
    },
  },
  getters: {
    USER(state){
      return state.user
    },
  },
  mutations: {
    SET_USER_TOKEN_ON_STATE(state, token) {
      localStorage.setItem('token', token)
      state.user.token = token;
    },
    USER_LOGOUT(state){
      localStorage.removeItem('token')
      state.user = {
        cards: [],
        operations: [],
      }
    },
    SET_USER_FIELDS_ON_STATE(state, data) {
      state.user.cards = data.cards
      state.user.name = data.firstName
      state.user.surname = data.lastName
      state.user.phone = data.phone
      state.user.email = data.email
      state.user.isEmailVerified = data.isEmailVerified
      state.user.avatar = data.avatar
      state.user.checked = data.wantsReciveNotes
    }
  },
  actions: {
    SET_USER_TOKEN({commit}, token) {
      commit('SET_USER_TOKEN_ON_STATE', token)
    },
    LOGOUT({commit}){
      console.log('LOGOUT')
      commit('USER_LOGOUT');
      // return new Promise((resolve) =>{
      //   resolve()
      // })
    },
    GET_USER_DETAILS_PROMISE({commit}){
      return new Promise((resolve, reject)=>{
        axios.get(
          `/api/v1/user_detail`,
          {headers:{'Authorization': `Token ${this.state.user.token}`}}
          )
          .then(function (response) {
            let data = response.data.userData
            data.phone = data.phone.replaceAll('-','')
            commit('SET_USER_FIELDS_ON_STATE', data );
            resolve()
          })
          .catch(function (error){
            console.log(error)
            if (error.response.status === 403) {
              commit('SET_USER_TOKEN_ON_STATE', null)
            }
            reject()
          });
      })
    },
    GET_USER_DETAILS({commit}){
      // console.log("GET_USER_DETAIL >> ", this.state.user.authToken)
      axios.get(
          `/api/v1/user_detail`,
          {headers:{'Authorization': `Token ${this.state.user.token}`}}
          )
          .then(function (response) {
            let data = response.data.userData
            data.phone = data.phone.replaceAll('-','')
            commit('SET_USER_FIELDS_ON_STATE', data );
          })
          .catch(function (error){
            console.log(error)
            if (error.response.status === 403) {
              commit('SET_USER_TOKEN_ON_STATE', null)
            }
          });
    },
  },
  modules: {
  }
})
