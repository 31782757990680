<template>
  <div class="d-flex flex-column sideBarWrap col-12 col-lg-3">
    <router-link @click="$emit('collapse-menu')" :to="{name: 'MyCards'}" class="sideBarLink d-flex">
      <SidebarItem :icon-src="require(`@/assets/media/card.svg`)">
        Карты
      </SidebarItem>
    </router-link>
    <router-link @click="$emit('collapse-menu')" :to="{name: 'HistoryOperation'}" class="historyOperation sideBarLink">
      <SidebarItem :icon-src="require(`@/assets/media/task.svg`)">
        История операций
      </SidebarItem>
    </router-link>
<!--    <router-link :to="{name: 'AboutProgram'}" class="aboutProgram sideBarLink">-->
<!--      <SidebarItem :icon-src="require(`@/assets/media/shield-tick.svg`)">-->
<!--        О программе-->
<!--      </SidebarItem>-->
<!--    </router-link>-->
    <a @click="$emit('collapse-menu')" target="_blank" href="http://kobart.ru/drivers/discount-card" class="promotions sideBarLink">
      <SidebarItem :icon-src="require(`@/assets/media/receipt-discount.svg`)">
        О программе
      </SidebarItem>
    </a>

<!--    <router-link :to="{name: 'Promotions'}" class="promotions sideBarLink">-->
<!--      <SidebarItem :icon-src="require(`@/assets/media/receipt-discount.svg`)">-->
<!--        Акции-->
<!--      </SidebarItem>-->
<!--    </router-link>-->
    <a @click="$emit('collapse-menu')" target="_blank" href="http://kobart.ru/news-and-actions/actions" class="promotions sideBarLink">
      <SidebarItem :icon-src="require(`@/assets/media/receipt-discount.svg`)">
        Акции
      </SidebarItem>
    </a>
    <router-link @click="$emit('collapse-menu')" :to="{name: 'Profile'}" class="profile sideBarLink">
      <SidebarItem :icon-src="require(`@/assets/media/personalcard.svg`)">
        Профиль
      </SidebarItem>
    </router-link>
    <router-link @click="logout" :to="{name: 'Login'}" class="base sideBarLink">
      <SidebarItem :icon-src="require(`@/assets/media/logout.svg`)">
        Выход
      </SidebarItem>
    </router-link>
  </div>
</template>

<script>
import SidebarItem from "@/components/SidebarItem";

export default {
  name: "SideBar",
  components: {
    SidebarItem
  },
  methods: {
logout() {
      this.$store.dispatch('LOGOUT')
      this.$router.push('/login')
    }
  },
}

</script>

<style scoped>
.sideBarLink {
  border-radius: 6px;
  padding: 18px 0 18px 32px;
  color: #000000;
  text-decoration: none;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
}
.router-link-active {
  background-color: #F9F9FC;
}
.sideBarWrap {
  margin-bottom: 24px;
}
</style>